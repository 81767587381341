import React from 'react';
import './article.css';

const Article = ({date, text, text1, text2, text3, text4, text5, text6 }) => (
  <div className="web__blog-container_article">
    <div className="web__blog-container_article-image">

    </div>
    <div className="web__blog-container_article-content">
      <div>
        <p>{date}</p>
        <h3>{text}</h3>
      </div>
      <p>{text1}</p>
      <p>{text2}</p>

    </div>
  </div>
);

export default Article;