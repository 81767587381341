import React from 'react';
import { dexL, coingeco, cmcL, cgL, unis} from './imports';
import './brand.css';

const Brand = () => (
  <div className="web__brand section__padding">
    <div>
      <img src={dexL} alt=""/>
    </div>
    <div>
      <img src={coingeco} alt="" />
    </div>
    <div>
      <img src={cmcL} alt="" />
    </div>
    <div>
      <img src={unis} alt=""/>
    </div>

  </div>
);

export default Brand;
