import React from 'react';

import './footer.css';

const Footer = () => (
  <div className="web__footer section__padding">
    <div className="web__footer-heading">
      <h1 className="gradient__text">Ready to Meme the Future with Dinger Kitten?</h1>
      <h2 className="gradient__text">Join Us Today and Be with the Pack!</h2>
    </div>

    <div className="web__footer-btn">
      <p><a href="">telegram</a></p>
    </div>


    <div className="web__footer-copyright">
      <p>@2023 DingerKitten. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;