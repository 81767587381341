import React from 'react';
import Feature from '../../components/feature/Feature';
import './what.css';

const What = () => (
  <div className="web__whatweb section__margin" id="wweb">
    <div className="web__whatweb-feature">
      <Feature title="What is $KITTEN" text="Dinger Kitten isn't your typical cryptocurrency; it's a meme coin designed to bring humor and entertainment to the world of digital assets. Dinger Kitten  has carved out its own unique niche in the crypto space." />
      <Feature title="In summary" text="Dinger Kitten  is a meme coin that brings an element of fun to the cryptocurrency world. It may not have the same level of technological innovation or celebrity endorsements as other coins, but for those who enjoy meme culture and a touch of humor in their crypto investments, it can be a playful addition to their portfolio." />
    </div>
    <div className="web__whatweb-heading">
      <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
  
    </div>
    <div className="web__whatweb-container">
      <Feature title="Embracing Meme Culture" text="Dinger Kitten  fully embraces the spirit of meme culture. Its name and branding are designed to be fun and lighthearted, appealing to those who enjoy the playful side of the crypto world." />
      <Feature title="Community-Driven" text="Like many meme coins, Dinger Kitten  relies on an active and passionate community of supporters. The coin's value and popularity are often driven by social media trends and online communities that come together to have a good time." />
      <Feature title="Trading and Speculation" text="While it may not have the same serious use cases as other cryptocurrencies, Dinger Kitten aims to be traded on various platforms. Some traders may see it as an opportunity for speculative gains, while others hold it as a humorous digital collectible." />
      <Feature title="Disclaimer" text="The information provided here is for informational and entertainment purposes only. It should not be considered financial, investment, or trading advice. Cryptocurrency investments, including meme coins like Dinger Kitten , are highly speculative and carry a risk of significant loss. Before making any financial decisions or investments, it is essential to conduct your research, seek professional advice, and fully understand the risks involved. Always invest an amount you can afford to lose, and consider your financial situation and risk tolerance carefully. The cryptocurrency market can be volatile and unpredictable, so exercise caution and make informed decisions" />
    </div>
  </div>
);

export default What;