import React from 'react';
import Article from '../../components/article/Article';
import './blog.css';

const Blog = () => (
  <div className="web__blog section__padding" id="blog">
    <div className="web__blog-heading">
      <h1 className="gradient__text">Contract</h1>
    </div>
    <div className="web__blog-container">

      <div className="web__blog-container_groupB">
        <Article date="" text="Contract" text1="Renounced" />
        <Article date="" text="Total Supply" text1="1.000.000.000"  />
        <Article date="" text="Sell Tax" text1="0" />
        <Article date="" text="Buy Tax" text1="0"  />
        <Article date="" text="Address" text1="0x2135asdrg" />
  
        
        
      </div>
    </div>
  </div>
);

export default Blog;