import React from 'react';

import d1 from '../../assets/d2.png';
import './header.css';

const Header = () => (
  <div className="web__header section__padding" id="home">
    
    <div className="web__header-image">
      <img src={d1}  alt=""/>
    </div>
    <div className="web__header-content">
      <h1 className="gradient__text">Let&apos;s Build amazing community </h1>

      <div className="web__header-content__input">    
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          window.location.href='http://google.com';
        }}> Buy $KITTEN</button>
      </div>

 
    </div>

  </div>
);
export default Header;