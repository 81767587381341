import React from 'react';
import './cta.css';
const CTA = () => (
  <div className="web__cta">
    <div className="web__cta-content">
      <h3>Join our community Today & start exploring the endless possibilities.</h3>
    </div>
    <div className="web__cta-btn">
      <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            window.location.href='http://google.com';
          }}>Telegram</button>
    </div>
  </div>
);

export default CTA;