import React from 'react';
import possibilityImage from '../../assets/d1.png';
import './possibility.css';
const featuresData = [
  {
    title: 'Total Supply',
    text: 'TBA',
  },
  {
    title: 'Tax',
    text: 'TBA',
  },
  {
    title: 'Marketing',
    text: 'TBA',
  },
  {
    title: 'Contract',
    text: 'TBA',
  },
  {
    title: 'Symbol',
    text: 'TBA',
  },
];
const Possibility = () => (
  <div className="web__possibility section__padding" id="possibility">
    <div className="web__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="web__possibility-content">
      <h1 className="gradient__text">The possibilities are <br /> beyond your imagination</h1>
      <p>The Dinger Kitten meme coin, the mission is to bring humor, fun, and a sense of community to the world of cryptocurrency. We embrace the spirit of meme culture, celebrating the quirky and the absurd. Our goal is to create a digital currency that not only offers the potential for speculative gains but also sparks laughter and fosters a strong and supportive community. We want Dinger Kitten  to be more than just a coin; we want it to be a source of entertainment and a lighthearted escape from the traditional financial world. Dinger Kitten  Meme Coin is a playful addition to the crypto landscape, and we encourage our community to engage in memes, jokes, and creativity. Together, we'll explore the lighter side of crypto, one meme at a time. Join us as we navigate the crypto universe with a smile, and remember, with Dinger Kitten , it's not just about the coin; it's about the meme!</p>
      <div className="web__possibility-content__input">    
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            window.location.href='http://google.com';
          }}> Buy $KITTEN</button>
      </div>
    </div>
  </div>
);

export default Possibility;